/* ==========================================================================
Lists
========================================================================== */

ul, ol {
	margin-top: 0;
	margin-bottom: $base-space-unit;

	li {
		margin-bottom: $base-space-unit * 0.5;

		ul, ol {
			margin-top: $base-space-unit * 0.5;
			margin-bottom: $base-space-unit * 0.5;
		}
	}
}

dl {
	margin-top: 0;
	margin-bottom: $base-space-unit;

	dt {
		font-weight: bold;
	}

	dd {
		margin-left: 0;
		padding-left: $base-space-unit;
		font-style: italic;
		margin-bottom: $base-space-unit;
	}
}