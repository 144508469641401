.basket {
  padding-top: 0 !important;

  &.standalone {
    padding-top: 6em !important;
    min-height: 600px;
  }

  p {
    text-align: center
  }

  &__heading {
    color: $dark-blue;
    font-family: $playfair;
    text-align: center;
    font-size: 2.5em;
    margin: 0 auto 40px auto;
  }

  &__container {
    @extend .container;
    background: $faded-blue;
    padding: 32px;
    max-width: 800px;
  }

  &__product {
    @extend .row;
    margin-bottom: 20px;
    padding: 20px;
    background: mix($faded-blue, $white);
    @extend %clearfix;

    &-image {
      @include grid-column(2);
      overflow: hidden;

      div {
        width: 100%;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &-title {
      @include grid-column(6);
    }

    &-quantity {
      @include grid-column(2);
      input {
        max-width: 96px;
        display: inline-block;
      }
    }

    &-price {
      @include grid-column(2);
    }

    &-button {
      @include grid-column(8);
    }
  }

  &__product-header {
    @extend .basket__product;
  }

}