.your-downloads {
  @extend .container;
  max-width: 800px;
  text-align: center;

  .resource-section__header, .resource-box {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-align: left;
  }

  h1 {
    @extend .shop-title;
    margin-right: 0;
  }
}