.resource-section {
  background-color: $faded-blue;

  &__container {
    @extend .container;
  }

  &__header {
    text-align: left;
    color: $dark-blue;
    @include grid-shift(1);
    @include grid-column(10);

    h2 {
      font-size: 3em;
      margin: 0 0 10px 0;
    }

    @media (max-width: 1000px) {
      @include grid-shift(0);
      @include grid-column(12);
      padding: 20px;
    }
  }

  &__resources {
    margin-bottom: 40px;
    @include grid-column(12);
  }

  .section-title {
    color: $dark-blue;
  }
}

.resource-box {
  padding: 0;
  background: mix($faded-blue, $white);
}

.resource-box__resource {
  margin: 0;
  padding: 20px;
  @extend %clearfix;

  &:nth-child(even) {
    background: lighten($faded-blue, 2.5%);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.resource-box__date,
.resource-box__name,
.resource-box__link {
  display: block;
}

.resource-box__date a {
  font-family: $header-font;
  font-size: 1.25em;
  color: $dark-blue;
  line-height: 1em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $purple;
  }
}

.resource-box__name {

  a {
    color: $purple
  }
}

.resource-box__link {

  .button {
    font-size: 1em;
  }
}

@media (min-width: $tablet-m) {

  .resource-box {
    @include grid-shift(1);
    @include grid-column(10);
  }

  .resource-box__date {
    @include grid-column(5);
  }

  .resource-box__name {
    @include grid-column(4);
  }

  .resource-box__link {
    @include grid-column(3);
    text-align: right;
  }
}