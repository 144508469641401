.begin-your-journey{

  padding-bottom: 0 !important;

  &__container {
    max-width: 700px;
    margin: auto;

    @media (max-width: 800px) {
      padding: 0 40px;
    }

    @media (max-width: 500px) {
      padding: 0;
    }

  }

  &__title {
    text-align: center;

    h3 {
      font-size: 3em;
      font-family: $playfair;
      color: $eie-green;
      line-height: 1;

      @media (max-width: 425px) {
        font-size: 2.2em;
      }
    }
  }
  &__body{

    text-align: center;


    @media (max-width: 500px){
      text-align: left;
    }

    p{
      text-align: left;
      margin: 40px 0;
    }

    a{
      color: $eie-darkblue;
      display: inline-block;
      font-family: $header-font;
      font-size: 1.8em;
      text-decoration: none;
      vertical-align: middle;
      line-height: 1;
      margin: 20px 0;

      @media (max-width: 700px) {
        font-size: 1.5em;
      }

      @media (max-width: 600px) {
        font-size: 1.2em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }


      span{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 25px;
        width: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }

  &__email{
    span{
      background-image: url('../../../data/cms_images/email.svg');
    }
  }

  &__telephone{
    span{
      background-image: url('../../../data/cms_images/phone.svg');
    }
  }

  &__image{
    background-image: url('../../../data/cms_images/plant.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 400px;
    width: 100%;
  }
}