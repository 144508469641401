// Removes the default styling added in most browsers, ie:
// - padding
// - margins
// - Bullet points on any <li> elements inside

@mixin delist() {
  padding: 0;
  margin: 0;
  list-style-type: none;
}


// Reset the grid-column value when using neat's grid

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter();
  }
  &:nth-child(#{$nth}+1) {
    clear: none
  }
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin transition($value...) {
  -moz-transition: $value;
  -o-transition: $value;
  -webkit-transition: $value;
  transition: $value;
}


@mixin transform($value...) {
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  -webkit-transform: $value;
  transform: $value;
}

@mixin box-sizing($value: border-box, $important: 0) {
  $after: "";
  @if $important != 0 {
    $after: " !important";
  }
  -moz-box-sizing: $value#{$after};
  -webkit-box-sizing: $value#{$after};
  box-sizing: $value#{$after};
}