.event-section {
	padding-top: $base-space-unit;
	padding-bottom: $base-space-unit;

	.section-title {
		color: $dark-blue;
	}
}


.event-box {
	border: 3px solid $dark-blue;
	padding: 40px;
}


.event-box__event {
	margin-bottom: $mobile-space-unit;
	@extend %clearfix;

	&:last-of-type {
		margin-bottom: 0;
	}
}


.event-box__date,
.event-box__name,
.event-box__link {
	display: block;
}

.event-box__date {
	font-family: $header-font;
	font-size: 20px;
	color: $dark-blue;
}

.event-box__name {
	margin-bottom: 10px;	
}


.event-box__link {

	a {

	}
}

@media (min-width: $tablet-m) {

	.event-box {
		@include grid-shift(1);
		@include grid-column(10);
	}

	.event-box__date {
		@include grid-column(3);
	}


	.event-box__name {	
		@include grid-column(3);
	}


	.event-box__link {
		@include grid-column(6);
		text-align: right;
	}
}