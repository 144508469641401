.quote-banner {
  height: 626px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;

  .container {
    height: 100%;
    position: relative;
  }

  @media(max-width: 880px) {
    height: 360px;
  }

  @media(max-width: 600px) {
    height: 240px;
  }
}

.quote-banner__quote {
  background-color: lighten($dark-blue, 50%);
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.1);
  color: $dark-blue;
  display: inline-block;
  max-width: 400px;
  padding: 48px;
  position: absolute;
  right: 0;
  bottom: $base-space-unit;

  @media (max-width: 990px) {
    display: none;
  }

  p {
    font-family: $merriweather;
    font-size: $font-size-6;
    margin-bottom: 15px;
    line-height: 1.5;
  }

  cite {
    font-size: $font-size-4;
    font-style: normal;
  }
}