// FONTS

// Import fonts here if necessary
// Remember that this scss is compiled, so if you're using a relative path to your font that may change

/* This stylesheet generated by Transfonter (https://transfonter.org) on May 12, 2017 8:46 AM */

@font-face {
  font-family: 'Woodford Bourne';
  src: url('../fonts/WoodfordBourne-Regular.eot');
  src: url('../fonts/WoodfordBourne-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/WoodfordBourne-Regular.woff') format('woff'),
  url('../fonts/WoodfordBourne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// $em-base should always be set in pixels
// Assign the body text and header text variables to the required font below
$em-base: 15px !global;
$line-height: 1.92;
$body-font: 'Open Sans', sans-serif;
$merriweather: 'Merriweather', serif;
$playfair: 'Playfair Display', serif;
$header-font: 'Woodford Bourne', sans-serif;
$header-font-weight: normal;

$default-link-decoration: underline;

// Font used in form objects like text inputs and labels
$form-font: sans-serif;

// All the font sizes in the design from smallest largest in rem units.

$font-size-0: $em-base; // body text
$font-size-1: 1.6em * 0.67;
$font-size-2: 1.8em * 0.67;
$font-size-3: 2.0em * 0.67;
$font-size-4: 2.3em * 0.67;
$font-size-5: 2.5em * 0.67;
$font-size-6: 3.3em * 0.67;
$font-size-7: 5.9em * 0.67;
$font-size-8: 6.3em * 0.67;
$font-size-9: 6.6em * 0.67;
