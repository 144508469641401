body > p {
  display: none;
}

.apprise {
  background: $white;
  padding: 32px;
  color: $dark-blue !important;

  &-buttons {
    text-align: left !important;

    button {
      top: 0 !important;
    }
  }

  &-inner {
    padding: 0 !important;
  }
}

.apprise h2 {
  font-size: 3em;
  margin-bottom: 20px;
}

.wysiwyg-editor {
  padding: 20px !important;
  margin: 0 !important;

  > p {
    display: block !important;
  }
}

.working {
  opacity: 0.5;
  cursor: not-allowed;
}

.hidden {
  display: none;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

legend {
  color: $dark-blue;
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 1.5em;
  margin: 0;
}

fieldset label {
  position: relative;
}

fieldset label span {
  position: absolute;
  top: 8px;
  right: 16px;
  color: $dark-blue;
}

.checkbox input {
  width: 32px;
  display: none;

}

.checkbox input + em {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: $white;
  border: 1px solid $dark-blue;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  top: 2px;
  margin-right: 8px;
  margin-bottom: 5px;
  float: left;
}

.checkbox input + em:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('../images/tick.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  left: 1px;
  bottom: -32px;
  transition: all 0.5s ease;
}

.checkbox input:checked + em:after {
  bottom: 0px;
}

.info-link {
  position: static;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 0 0 0.25em;

  &:hover {
    color: darken($dark-blue, 5%);
  }
}