.eie-feature {
  &__container {
    max-width: 990px;
    margin: 0 auto;
  }

  &__quote {
    background-image: url("../../../data/cms_images/leafy-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    position: relative;
    padding: 60px;
    width: 60%;
    display: inline-block;
    vertical-align: top;

    @media (max-width: 800px) {
      width: 100%;
    }

    @media (max-width: 425px) {
      padding: 40px;
    }

    @media (max-width: 320px) {
      padding: 30px;
    }
  }

  &__overlay {
    background-color: $eie-grey;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__text {
    position: relative;
    z-index: 10;
    font-family: $header-font;
    font-size: 2em;
    line-height: 1.2;

    ul {
      list-style: none;
    }

    li {
      margin-left: 1em;
      margin-bottom: 0;

      &::before {
        content: '—';
        display: inline-block;
        width: 1em;
        margin-right: 0em;
        margin-left: -1em;
      }
    }

    @media (max-width: 900px) {
      font-size: 1.8em;

      p {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 425px) {
      font-size: 1.5em;
    }
  }

  &__button {
    padding: 10px 20px;
    background-color: $white;
    color: $eie-grey;
    font-weight: 700;
    text-decoration: none;
    font-size: 0.8em;
    &:hover{
      cursor: pointer;
    }
  }

  &__eie {
    width: 40%;
    display: inline-block;
    background-color: $eie-light;
    vertical-align: top;
    padding: 14% 0;

    h2 {
      font-family: "Playfair Display", serif;
      color: $white;
      line-height: 1;
      text-align: center;
      font-size: 3em;
      margin-bottom: 10px;
    }
    .inspiring {
      font-size: 4em;
    }

    .equipping {
      font-size: 3.55em;
    }

    @media (max-width: 900px) {
      h2 {
        font-size: 2.5em;
        margin-bottom: 10px;
      }

      .inspiring {
        font-size: 3.3em;
      }

      .equipping {
        font-size: 2.9em;
      }
    }

    @media (max-width: 800px) {
      width: 100%;
      padding: 10% 30%;

      h2 {
        font-size: 3em;
        margin-bottom: 10px;
      }

      .inspiring {
        font-size: 4.1em;
      }

      .equipping {
        font-size: 3.7em;
      }
    }

    @media (max-width: 650px) {
      padding: 18% 26%;

      h2 {
        font-size: 3em;
      }

      .inspiring {
        font-size: 4.1em;
      }

      .equipping {
        font-size: 3.6em;
      }
    }

    @media (max-width: 550px) {
      padding: 30% 20%;
    }

    @media (max-width: 500px) {
      padding: 30% 19%;
    }

    @media (max-width: 425px) {
      padding: 30% 19%;

      h2 {
        font-size: 2.5em;
      }

      .inspiring {
        font-size: 3.4em;
      }

      .equipping {
        font-size: 3.05em;
      }
    }

    @media (max-width: 425px) {
      h2 {
        font-size: 2.2em;
      }

      .inspiring {
        font-size: 3em;
      }

      .equipping {
        font-size: 2.7em;
      }
    }

    @media (max-width: 320px) {
      padding: 49% 12%;
    }
  }

  &__underline {
    background-color: $eie-purple;
    height: 10px;
    width: 66%;
    margin: 50px auto 0;

    @media (max-width: 900px) {
      width: 65%;
    }

    @media (max-width: 800px) {
      width: 270px;
      margin: 50px auto 0;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }
}