.mail-sign-up {
  background-color: #7BB142;
  opacity: 0.8;
  padding: 50px;

  .container {
    max-width: 900px;

    .mail-sign-up__content {
      .col-1 {
        @include grid-column(4);

        h4 {
          font-size: 1.8em;
          font-weight: 700;
          font-style: italic;
          color: #fff;
          margin-bottom: 0;
          font-family: $playfair;
        }
        p {
          color: #fff;
          font-size: 1em;
          line-height: 1;
        }
      }
      .col-2 {
        @include grid-column(8);

        .email {
          width: 350px;
          display: inline-block;
          color: #3C3C3C !important;
          border: 1px solid #F7F7F7;

          &::placeholder {
            color: #3C3C3C !important;
          }

          &:-ms-input-placeholder {
            color: #3C3C3C;
          }

          &::-ms-input-placeholder {
            color: #3C3C3C;
          }
        }
      }

      .clear {
        max-width: 200px;
        margin: 0;
        display: inline-block;
        margin-left: 20px;

        .button {
          padding: 7px;
          background-color: #bf5c8a;

          &:hover {
            background-color: #bf4884;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mail-sign-up {
    .col-1 {
      width: 100% !important;
    }

    .col-2 {
      width: 100% !important;
    }
  }
}

@media (max-width: 425px) {
  .mail-sign-up {
    .email{
      width: 250px !important;
    }
  }
}

.site-footer {
  background-color: $green;
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;
  font-family: $header-font;

  a {
    color: $white;
    text-decoration: none;
    display: block;

  }

  .info-row {
    @extend %clearfix;

    .col-1 {
      @include grid-column(2);

      img {
        max-width: 196px;
      }
      margin-bottom: $mobile-space-unit;
    }

    .col-2 {
      text-align: center;
      @include grid-column(8);
      font-size: 24px;
      margin-bottom: $mobile-space-unit;

      p {
        color: white;
      }

      a {
        margin-top: 32px;
        line-height: 1;
        &:first-of-type {
          margin-bottom: 30px;
        }
      }

      @media(max-width: 990px) {
        font-size: 1em;
      }
    }

    .col-3 {
      @include grid-column(2);
      font-size: 24px;
      text-align: right;

      address {
        color: $white;
        font-style: normal;
        line-height: 1.55;
      }
    }
  }

  .credit-row {
    padding-top: 64px;

    p, a {
      color: lighten($green, 25%);
      font-size: 1.25em;
    }

    a {
      display: inline-block;

      &:hover {
        color: $white;
      }
    }

    .col-1 {
      text-align: left;
      @include grid-column(6);
    }

    .col-2, .col-3 {
      text-align: right;
      @include grid-column(2);
    }

    @media (max-width: 1200px) {
      .col-1 {
        @include grid-column(6);
      }

      .col-2 {
        @include grid-column(2.5);
        text-align: right;
      }

      .col-3 {
        @include grid-column(11);
        text-align: right;
      }
    }

    @media (max-width: 1000px) {
      .col-1 {
        @include grid-column(6);
      }

      .col-2 {
        @include grid-column(5.5);
        text-align: right;
      }

      .col-3 {
        @include grid-column(11.5);
        text-align: right;
      }
    }

    @media (max-width: 900px) {
      .col-1, .col-2, .col-3 {
        @include grid-column(12);
        text-align: left;
      }
    }
  }
}

/*@media (min-width: $tablet-m) {
  .site-footer {

    .info-row {
      .col-1 {
        @include grid-shift(1);
        @include grid-column(1.5);
      }

      .col-2 {
        @include grid-shift(1);
        @include grid-column(2.5);
      }

      .col-3 {
        @include grid-shift(2);
        @include grid-column(3);
      }
    }
  }
}*/

.social-link {
  margin: 24px 16px 0 0;
  width: 24px;
  height: auto;
  display: inline-block !important;
}

.message-us {
  width: auto;
  background: #0084FF;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 32px;
  z-index: 10000;

  img {
    width: 32px;
    height: auto;
    display: inline-block;
    vertical-align: middle
  }

  span {
    padding: 0 11px 0 0;
  }
}
