.blog-list {
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;
  background-color: $faded-blue;
}

.blog-card {
  display: block;
  margin-bottom: $base-space-unit;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: all 0.1s ease-out;

  &:hover {
    transform: scale(0.95);
  }
}

.blog-card__image {
  height: 13.5em;
  background-size: cover;
}

.blog-card__text {
  background-color: $white;
  padding: 2em 1.75em;
}

.blog-card__title {
  font-family: $playfair;
  font-size: 3em;
  color: $green;
  margin-bottom: 0;
  padding-bottom: 32px;
}

.blog-card__date {
  font-size: $font-size-4 * 0.7;
  color: $dark-blue;
  text-decoration: none;
}

@media (min-width: $tablet-m) {

  .blog-card__image {
    height: auto;
    left: 8.5298%;
    position: absolute;
    width: 42.64902%;
    top: 0;
    bottom: 0;
  }

  .blog-card__text {
    padding: 4em 3.5em;
    margin-left: 8.5298 + 42.64902%;
    width: 40.29137%;
  }

  .blog-card__title {
    font-size: 3em;
  }

  .blog-card__date {
    font-size: 1.25em;
  }
}