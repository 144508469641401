.modal-bg {
	display: none;
	background: $dark-blue;
	opacity: .86;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	transition: all .2s ease-in-out;


	&.is-open {
		display: block;
	}
}