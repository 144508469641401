.text-block {
  &-blue {
    background: $eie-lightblue;

    &__container {
      max-width: 700px;
      margin: auto;

      @media (max-width: 800px) {
        padding: 0 40px;
      }

    }

    &__title {
      text-align: center;

      h3 {
        font-size: 3em;
        font-family: $playfair;

        @media (max-width: 425px) {
          font-size: 2.2em;
        }

      }
    }

    &__body {
      ul {
        list-style: none;

      }

      li {

        margin-left: 2em;

        &::before {
          content: '—';
          display: inline-block;
          width: 1em;
          margin-right: 1em;
          margin-left: -2em;
        }
      }

    }
  }

  &-white {
    background: $white;

    &__container {
      max-width: 700px;
      margin: auto;

      @media (max-width: 800px) {
        padding: 0 40px;
      }

    }

    &__title {
      text-align: center;

      h3 {
        font-size: 3em;
        font-family: $playfair;
        color: $eie-green;

        @media (max-width: 425px) {
          font-size: 2.2em;
        }

      }
    }

    &__body {
      ul {
        list-style: none;

      }

      li {

        margin-left: 2em;

        &::before {
          content: '—';
          display: inline-block;
          width: 1em;
          margin-right: 1em;
          margin-left: -2em;
        }
      }

    }
  }
}