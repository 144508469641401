.shop-inner {

  &__container {
    @extend .container;
  }

  &__image {
    @include grid-column(5);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
    @include grid-column(6);
    @include grid-shift(1);

    &-title {
      color: $purple;
      font-family: $playfair;
    }

    &-price {
      text-align: center;

      strong {
        font-family: $playfair;
        font-size: 1.5em;
      }
    }

    &-button {
      padding: 20px;
      background: $faded-blue;
      @extend %clearfix;

      .shop-inner__info-price {
        @include grid-column(4);
      }

      .shop-inner__info-quantity {
        @include grid-column(2);
        text-align: center;
        margin-bottom: 0;
      }

      button {
        @include grid-column(6);
        padding: 6px 0;
      }
    }
  }

  &__variant {
    width: 100%;

    &-title {
      @include grid-column(6);
    }

    &-price {
      @extend .shop-inner__info-price;
      @include grid-column(4);
    }

    &-quantity {
      @include grid-column(2);
    }
  }

  @media (max-width: 1200px) {
    &__image {
      @include grid-column(8);
    }

    &__info {
      @include grid-column(12);
    }
  }

}