.about-section {
  background-color: $new-blue;
  padding-top: $base-space-unit * 2;
  padding-bottom: $base-space-unit * 2;

  ul {
    margin-top: 16px;
    li {
      margin-bottom: 0px;
    }
  }

  h3 {
    text-align: center;
    font-family: $playfair;
    text-align: center;
    font-size: 2.5em;
    opacity: 0.5;
  }
}

.about-section__text-box {
  background-color: $white;
  padding: 96px 64px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);

  p, ul, h1, h2, h3, h4, h5 {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.about-section__profile {
  margin-top: 64px;
}

.about-section__profile-image {
  height: 87px;
  width: 87px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.7em;
}

.about-section__profile-text {
  display: inline-block;
  vertical-align: middle;

  p {
    margin-bottom: 0;
  }

  strong {
    font-weight: 600;
  }
}

@media (min-width: $tablet-m) {
  .about-section__text-box {
    @include grid-shift(2);
    @include grid-column(8);
  }
}