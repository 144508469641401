.mobile-header {
  background: transparent url('../images/leaves.jpg') center no-repeat;
  padding: 0 15px;
  height: 5em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-10;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  .row {
    position: relative;
    z-index: 100
  }
}

.mobile-header__icon {
  display: inline-block;
  height: 33px;
  width: 34px;
  margin-top: 15px;
}

.mobile-header__button {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: transparent url('../images/menu-toggle.svg') center no-repeat;
  background-size: contain;
  float: right;
  margin-top: 15px;
  cursor: pointer;
}

.mobile-header__nav {
  list-style: none;
  margin: 0 -15px 0;
  padding: 2em 15px 2em;
  position: relative;
  z-index: $z-index-1;
  display: none;

  li {
    margin-bottom: 5px;
    color: $white;
    font-size: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 18px;
      color: $white;
      text-decoration: none;
    }

    ul {
      margin: 1em 0 1em 5px;
      list-style: none;
      border-left: 2px solid white;
      padding-left: 1em;

      li, a {
        font-size: 11px;
      }
    }
  }
}

body.mobile-is-open {

  .mobile-header__button {
    background-image: url("../images/menu-close.svg");
  }

}

@media(min-width: $tablet-m) {
  .mobile-header {
    display: none;
  }
}