/* ==========================================================================
Form, Label and Legend & Inputs
========================================================================== */
/* All rules within this section are opinionated. They can be removed or altered
without cross-browser rendering being affected */

form {
  margin-bottom: 0;
  font-family: $form-font;
}

label {
  display: block;
  margin-bottom: $base-space-unit * 0.1;
}

legend {
  display: block;
  margin-bottom: $base-space-unit;
}

/* ==========================================================================
Buttons and Button-Like Inputs
========================================================================== */

/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 */

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {

  background: $green; /* 1 */
  border: 1px solid $green; /* 2 */
  border-radius: 0; /* 3 */
  text-align: center; /* 4 */
  -webkit-appearance: none; /* 5 */
  -moz-appearance: none; /* 5 */
  appearance: none; /* 5 */

  // ---------- Everything below this line is an opinionated choice -----------
  color: $white;
  white-space: nowrap;
  font-family: $header-font;
  font-size: 20px;
  margin-bottom: 0;
  padding: 6px 6px;
  cursor: pointer;
  display: inline-block;
  min-width: 162px;
  transition: all .2s ease-in-out;

  &:hover,
  &:focus {
    outline: none;
    //background: $primary-colour !important;
    //color: $white;
  }
}

/* ==========================================================================
Inputs
========================================================================== */

/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 * 6. Keeps textarea responsive even when cols attribute set.
 *
 */

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select,
.input {
  border: 1px solid $light-blue; /* 2 */
  border-radius: 0; /* 3 */
  text-align: left; /* 4 */
  font-size: 18px;
  padding: .3em .8em;
  font-family: $body-font;
  box-shadow: none; /* 5 */

  width: 100%; /* 6 */

  // ---------- Everything below this line is an opinionated choice -----------
  display: block;
  color: $light-blue;
  margin-bottom: 1.6em;

  &:hover,
  &:focus {
    outline: none;
  }
}

textarea {
  height: 10em;
}

input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
}

/* ==========================================================================
Input Placeholders
========================================================================== */
/**
 * 1. Set placeholder text to same opacity across browsers.
 */

::-webkit-input-placeholder {
  color: $light-blue;
  opacity: 1; /* 1 */
}

:-moz-placeholder { /* Firefox 18- */
  color: $light-blue;
  opacity: 1; /* 1 */
}

::-moz-placeholder { /* Firefox 19+ */
  color: $light-blue;
  opacity: 1; /* 1 */
}

:-ms-input-placeholder {
  color: $light-blue;
  opacity: 1; /* 1 */
}
