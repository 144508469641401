.video-slide {

	padding-top: $base-space-unit;
	padding-bottom: $base-space-unit;

	.section-title {
		color: $red;
	}
}


.embed-container { 
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}




@media (min-width: $tablet-m) {
	.video-slide .row {
		@include grid-shift(1);
		@include grid-column(10);
	}
}