/* ==========================================================================
Headings
========================================================================== */

/* 
* The CSS below for the headings really looks like it should be abstracted out to 
* something more dry but the idea here is that it provides a super easy place to make
* any small tweaks that the specific project might require.
*
* The extra h7 class is just for common areas where text is small eg copyright in footer,
* it's smaller than the body text.
* 
* The .h1 - .h7 classes only imitate the sizing on h1 - h6 elements, not the font properties.
*/

h1, h2, h3,
h4, h5, h6 {
  font-family: $header-font;
  font-weight: $header-font-weight;
}

h1 {
  font-size: $font-size-8;
  line-height: 1.2;
  margin: 0 0 $base-space-unit * 0.85;
}

h2 {
  font-size: $font-size-9;
  line-height: 1.2;
  margin: 0 0 $base-space-unit;
}

h3 {
  margin: 0 0 16px 0;
  font-family: 'Woodford Bourne';
  font-size: 2em;
  line-height: 1.5em;
  color: #00AAE2;
}

h4 {
  font-size: $font-size-3;
  line-height: 1.3;
  margin: 0 0 $base-space-unit;
}

h5 {
  font-size: $font-size-2;
  line-height: 1.35;
  margin: 0 0 $base-space-unit;
}

h6 {
  font-size: $font-size-1;
  line-height: 1.4;
  margin: 0 0 $base-space-unit;
}

// BASIC TEXT ELEMENTS
p, hr {
  margin: 0 0 1.92em;
}

a {
  display: inline-block;
  color: $default-link-colour;
  text-decoration: $default-link-decoration;
}

blockquote {
  margin: 0 0 $base-space-unit;
  border-left: 5px solid $secondary-colour;
  padding: 0.7em 0 0.7em 1.5em;
  font-style: italic;

  &:before {
  }
  &:after {
  }

  p:last-of-type {
    margin-bottom: 0.4em;
  }

  cite {
    display: block;
    font-style: normal;
  }
}

pre {
  margin: 0 0 $base-space-unit;
  background: $pre-bg-colour;
  padding: $base-space-unit;
  color: $pre-text-colour;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap; // Mozilla, since 1999
  white-space: -pre-wrap; // Opera 4-6
  white-space: -o-pre-wrap; // Opera 7
  word-wrap: break-word; // Internet Explorer 5.5+
  height: 300px;
  overflow-y: scroll;
}

caption {
}

// Inline elements

mark {
  background: $mark-bg-colour;
  color: inherit;
}

code {
  color: inherit;
}

a, a:visited, a:active {
  outline: none !important;
}
