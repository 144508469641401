.large-form{
  width: 47% !important;
  float: right;
  margin-top: 40px;
}

.full-screen{
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 999999;
}