.bible-quote {
  background: $eie-lightgrey;
  padding: 40px 0 !important;

  &__container {
    max-width: 570px;
    margin: auto;
    padding: 0 30px;
  }

  &__bible {
    background-image: url("../../../data/cms_images/bible.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 50px;
    width: 80px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 650px) {
      height: 40px;
    }
  }

  h3 {
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px;
    color: $body-colour;
    font-size: 2.5em;

    @media (max-width: 650px) {
      font-size: 2em;
    }

    @media (max-width: 425px) {
      font-size: 1.8em;
    }

    @media (max-width: 370px) {
      margin-top: 10px;
    }
  }

  &__quote {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 1.3em;

    @media (max-width: 650px) {
      font-size: 1.1em;
    }
    
    @media (max-width: 370px) {
      margin-top: 10px;
      margin-left: 20px;
    }

    p {
      margin: 0;
    }
  }
}