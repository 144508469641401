.cookie-popup {
  background: $white;
  width: 100%;
  max-width: 480px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  //@include box-shadow(0 0 30px 10px rgba($black, 0.44));
  padding: 40px 40px (40px + 10px) 40px;
  @include transition(all 0.5s ease-out);
  border: 2px solid $green;

  &:after {
    content: '';
    background: $green;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20px;
    left: 0;
  }

  .button {
    p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 780px) {
    max-width: calc(100% - 40px);
    padding: 30px 30px (30px + 10px) 30px;
    top: 20px;
    bottom: unset;
  }

  @media (max-width: 400px) {
    .button {
      width: 100%;
    }
  }
}