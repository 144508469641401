.header-basket {
  display: inline-block;
  position: relative;
  color: $white;
  text-align: center;
  cursor: pointer;
  padding: 0 0 0 40px;
  top: 18px;

  &__icon {
    display: inline-block;
    background-image: url('../images/basket.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  &__count {
    width: 100%;
    text-align: center;
    display: block;
    position: relative;
    top: 4px;
  }

  &.mobile{
    padding: 0;
    top: 0;
  }
}