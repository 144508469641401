.global-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
}

.header-nav {
  background: transparent url('../images/leaves.jpg') center no-repeat;
  background-size: cover;
  height: 160px;
  padding: 0 32px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 97;
    max-height: 160px;
  }

  & > div {
    z-index: 100;
    position: relative
  }
}

.nav-header__icon {
  display: inline-block;
  height: 93px;
  width: 137px;
  margin-top: 30px;
  vertical-align: middle;
}

.nav-header__nav {
  height: 160px;
  float: right;

  a {
    font-size: 1.25em;
    color: $white;
    text-decoration: none;
    padding: 0 1em;
    line-height: 160px;
    transition: all 0.2s ease;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.course-button {
  transition: all .1s ease-in-out;

  &.is-open {
    background-color: $white;
    color: $body-colour;
  }
}

.header-courses {
  background-color: $white;
  padding: $mobile-space-unit 15px;
  display: none;
  box-shadow: 0px 360px 120px 100px rgba(0, 0, 0, 0.5);

  &.is-open {
    display: block;
  }
}

.header-courses__course {
  @include grid-column(1 of 4);
  margin-left: 0;
  text-decoration: none;
  color: $white;
  padding: 20px;
  height: auto;
  cursor: pointer;
  transition: all 0.1s ease-out;

  &-row{
    display: flex;
    gap: 16px;
    align-content: center;
    align-items: stretch;
  }

  &.residential {
    background-color: $light-blue;
  }

  &.local {
    background-color: $green;
  }

  &.start {
    background-color: $red;
  }

  &.moving {
    background-color: $dark-blue;
  }

  &.diocean {
    background-color: $purple;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.header-courses__title {
  font-family: $playfair;
  font-size: $font-size-6 * 0.7;
  margin-bottom: 20px;
  color: $white;
}

.header-courses__sub {

}

@media (min-width: $tablet-m) {
  .global-header {
    display: block;
  }
}

// 1024 for full font size

@media (min-width: $desktop-l) {

  .header-courses__title {
    font-size: 2em;
  }
}